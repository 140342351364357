import React from "react"
import TicTac from "../components/ticTacView"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TicTacToe = () => (
  <Layout>
    <SEO title="Tic Tac Toe" />
    <TicTac />
  </Layout>
)

export default TicTacToe