import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Container, P20, Column12 } from "../theme/styles";
import * as types from "../store/reducers/types";

const TicTacWrapper = styled.div`
height: 100%;
width: 100%;
background: #E84545;
min-height: 100vh;
`;

const TicTacContainer = styled.div`
min-height: 50vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-right: 50px;
padding-left: 50px;
padding-top: 50px;
padding-bottom: 50px;
`;

const BoardGrid = styled.div`
display: grid;
grid-template-columns: auto auto auto;
 `
const Block1 = styled.div`
 display: flex;
 width: 120px;
 height: 100px;
 align-items: center;
 justify-content: center;
 font-size: 3rem;
 font-weight: bold;
 border: 7.5px solid #2B2E4A;
 border-top: none;
 border-left: none;
 border-right: none;
 transition: background 0.2s ease-in-out;
 `
const Block2 = styled.div`
 display: flex;
 width: 120px;
 height: 100px;
 align-items: center;
 justify-content: center;
 font-size: 3rem;
 font-weight: bold;
 border: 7.5px solid #2B2E4A;
 border-top: none;
 transition: background 0.2s ease-in-out;
 `
const Block3 = styled.div`
 display: flex;
 width: 120px;
 height: 100px;
 align-items: center;
 justify-content: center;
 font-size: 3rem;
 font-weight: bold;
 border: 7.5px solid #2B2E4A;
 border-top: none;
 border-right: none;
 border-left: none;
 transition: background 0.2s ease-in-out;
 `
const Block4 = styled.div`
 display: flex;
 width: 120px;
 height: 100px;
 align-items: center;
 justify-content: center;
 font-size: 3rem;
 font-weight: bold;
 border: 7.5px solid #2B2E4A;
 border-bottom: none;
 border-right: none;
 border-left: none;
 border-top: none;
 transition: background 0.2s ease-in-out;
 `
const Block5 = styled.div`
 display: flex;
 width: 120px;
 height: 100px;
 align-items: center;
 justify-content: center;
 font-size: 3rem;
 font-weight: bold;
 border: 7.5px solid #2B2E4A;
 border-bottom: none;
 border-top: none;
 transition: background 0.2s ease-in-out;
 `
const CustomButton = styled.button`
width: 120px;
height: 100px;
background: none;
border: none;
font-family: 'Indie Flower', cursive;
line-height: 0.2;
font-size: 136px;
outline: none;
color: #FFF;
cursor: pointer;
transition: all 0.5s ease;
z-index: 10;
-webkit-tap-highlight-color: transparent;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
&:hover {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &:active {
    outline: none;
    border: none;
  }
`
const ResetButton = styled.button`
border-radius: .25rem;
height: calc(1.5em + .75rem + 6px);
background: #febf63;
border: none;
line-height: 0.2;
font-size: 15px;
color: #2b2e4a;
cursor: pointer;
box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
font-family: 'Roboto', sans-serif;
outline: none;
-webkit-box-shadow: 1px 10px 29px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 10px 29px -10px rgba(0,0,0,0.75);
box-shadow: 1px 10px 29px -10px rgba(0,0,0,0.75);
`

const Title = styled.span`
text-align: center;
width: 100%;
font-family: Roboto Condensed;
font-size: 25px;
font-weight: 700;
color: #ececec;
`


function Box(props) {
    return (
        <CustomButton
            onClick={props.onClick}
        >
            {props.value}
        </CustomButton>
    )
}

const TicTac = () => {
    const squares = useSelector((state) => state.ticTac.squares);
    const dispatch = useDispatch();

    const renderSquare = i => {
        return (
            <Box
                value={squares[i]}
                onClick={() => dispatch({ type: types.PLAY, value: i })}
            />);
    }

    const calculateWinner = squares => {
        const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];
        for (let i = 0; i < lines.length; i++) {
            const [a, b, c] = lines[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
                return squares[a];
            }
        }
        return null;
    }
    const winner = calculateWinner(squares);
    let status;
    if (winner) {
        const send = () => dispatch({ type: types.GAMESTATUS })
        send()
        status = 'Winner: ' + winner;
    } else {
        status = 'Winner: None'
    }

    return (
        <TicTacWrapper>
            <Container>
                <Column12>
                <P20 />
                <Title>TIC TAC TOE</Title>
                <Title>{status}</Title>
                </Column12>
            </Container>
            <TicTacContainer>
                <BoardGrid>
                    <Block1>{renderSquare(0)}</Block1>
                    <Block2>{renderSquare(1)}</Block2>
                    <Block3>{renderSquare(2)}</Block3>
                    <Block1>{renderSquare(3)}</Block1>
                    <Block2>{renderSquare(4)}</Block2>
                    <Block3>{renderSquare(5)}</Block3>
                    <Block4>{renderSquare(6)}</Block4>
                    <Block5>{renderSquare(7)}</Block5>
                    <Block4>{renderSquare(8)}</Block4>
                </BoardGrid>
            </TicTacContainer>
            <Container>
                <ResetButton onClick={() => dispatch({ type: types.RESET })}>Reset Game</ResetButton>
            </Container>
        </TicTacWrapper>
    );
}

export default TicTac;